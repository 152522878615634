export const mygp_with_reference = "mygp-with-msisdn";
export const mygp_without_reference = "mygp-without-msisdn";
export const digitantra_ref = "digitantra";

export const truecaller_packages = [
    {
        id: 38,
        slug: "weekly",
        title: "Weekly Pack",
        src: "/assets/images/WeeklyButton.png",
    },
    {
        id: 39,
        slug: "monthly",
        title: "Monthly Pack",
        src: "/assets/images/MonthlyButtonThin.png",
    },
    {
        id: 37,
        slug: "daily",
        title: "Daily Pack",
        src: "/assets/images/DailyButton.png",
    },
];

export const truecaller_nagad_packages = [
    {
        id: 39,
        slug: "monthly",
        title: "Monthly Pack",
        src: "/assets/images/Truecaller/Nagad/MonthlyButton.png",
    },
    {
        id: 41,
        slug: "yearly",
        title: "Yearly Pack",
        src: "/assets/images/Truecaller/Nagad/YearlyButton.png",
    },
    {
        id: 40,
        slug: "half-yearly",
        title: "Half Yearly Pack",
        src: "/assets/images/Truecaller/Nagad/HalfYearlyButton.png",
    },
    {
        id: 37,
        slug: "daily",
        title: "Daily Pack",
        src: "/assets/images/Truecaller/Nagad/DailyButton.png",
    },
    {
        id: 38,
        slug: "weekly",
        title: "Weekly Pack",
        src: "/assets/images/Truecaller/Nagad/WeeklyButton.png",
    },
];

export const truecaller_monthly_yearly = [
    {
        id: 39,
        slug: "monthly",
        title: "Monthly Pack",
        src: "/assets/images/Truecaller/Nagad/MonthlyButton.png",
    },
    {
        id: 41,
        slug: "yearly",
        title: "Yearly Pack",
        src: "/assets/images/Truecaller/Nagad/YearlyButton.png",
    },
    {
        id: 40,
        slug: "half-yearly",
        title: "Half Yearly Pack",
        src: "/assets/images/Truecaller/Nagad/HalfYearlyButton.png",
    },
];

export const truecaller_daily_weekly = [
    {
        id: 38,
        slug: "weekly",
        title: "Weekly Pack",
        src: "/assets/images/Truecaller/Nagad/WeeklyButton.png",
    },
    {
        id: 37,
        slug: "daily",
        title: "Daily Pack",
        src: "/assets/images/Truecaller/Nagad/DailyButton.png",
    },
];

export const truecaller_campaign_partners = [
    {
        id: 1,
        slug: "rm",
        ref: "digitantra",
        title: "Digitantra",
    },
    {
        id: 2,
        slug: "nagad",
        ref: "nagad",
        title: "Nagad",
    },
    {
        id: 3,
        slug: "bkash",
        ref: "bkash",
        title: "bKash",
    },
];

export const truecaller_app_link = "https://tc.onelink.me/571208033/abwmjqdl";

export const dob_services_configurations = [
    {
        id: 37,
        keyword: "Truecaller",
        description: "Truecaller (Daily pack)",
        amount: 5,
        validity_in_days: 1,
        logo: "/assets/images/Dob Services/truecaller_logo.png",
        banner: "/assets/images/Dob Services/truecaller_banner.png",
    },
    {
        id: 38,
        keyword: "Truecaller",
        description: "Truecaller (Weekly pack)",
        amount: 35,
        validity_in_days: 7,
        logo: "/assets/images/Dob Services/truecaller_logo.png",
        banner: "/assets/images/Dob Services/truecaller_banner.png",
    },
    {
        id: 39,
        keyword: "Truecaller",
        description: "Truecaller (Monthly pack)",
        amount: 120,
        validity_in_days: 30,
        logo: "/assets/images/Dob Services/truecaller_logo.png",
        banner: "/assets/images/Dob Services/truecaller_banner.png",
    },
    {
        id: 3,
        keyword: "BedtimeStories",
        description: "Bedtime Stories (Daily pack)",
        amount: 5,
        validity_in_days: 1,
        logo: "/assets/images/Dob Services/bedtime_stories_logo.png",
        banner: "/assets/images/Dob Services/bedtime_stories_banner.jpg",
    },
    {
        id: 4,
        keyword: "DocuBay",
        description: "DocuBay (Daily pack)",
        amount: 5,
        validity_in_days: 1,
        logo: "/assets/images/Dob Services/docubay.png",
        banner: "/assets/images/Dob Services/docubay-banner.jpg",
    },
    {
        id: 5,
        keyword: "DocuBay",
        description: "DocuBay (Weekly pack)",
        amount: 30.99,
        validity_in_days: 7,
        logo: "/assets/images/Dob Services/docubay.png",
        banner: "/assets/images/Dob Services/docubay-banner.jpg",
    },
    {
        id: 6,
        keyword: "Actionbox",
        description: "Action Box (Daily pack)",
        amount: 5.0,
        validity_in_days: 1,
        logo: "/assets/images/Dob Services/actionbox-logo.png",
        banner: "/assets/images/Dob Services/actionbox-banner.png",
    },
    {
        id: 7,
        keyword: "Actionbox",
        description: "Action Box (Weekly pack)",
        amount: 5.0,
        validity_in_days: 7,
        logo: "/assets/images/Dob Services/actionbox-logo.png",
        banner: "/assets/images/Dob Services/actionbox-banner.png",
    },
    {
        id: 10,
        keyword: "ArtDeepFilters",
        description: "Art Deep Filters (Daily pack)",
        amount: 5.0,
        validity_in_days: 1,
        logo: "/assets/images/Dob Services/adf_logo.png",
        banner: "/assets/images/Dob Services/adf_banner.png",
    },
    {
        id: 11,
        keyword: "Ubundle",
        description: "Ubundle (Daily pack)",
        amount: 5.0,
        validity_in_days: 1,
        logo: "/assets/images/Dob Services/ubundle_logo.png",
        banner: "/assets/images/Dob Services/ubundle_banner.png",
    },

    {
        id: 12,
        keyword: "GoldKlass",
        description: "GoldKlass (Daily pack)",
        amount: 5.0,
        validity_in_days: 1,
        logo: "/assets/images/Dob Services/GoldKlass.png",
        banner: "/assets/images/Dob Services/GoldKlass-banner.jpg",
    },
    {
        id: 13,
        keyword: "ScienceBay",
        description: "ScienceBay (Daily pack)",
        amount: 5.0,
        validity_in_days: 1,
        logo: "/assets/images/Dob Services/ScienceBay.png",
        banner: "/assets/images/Dob Services/ScienceBay-banner.png",
    },
    {
        id: 14,
        keyword: "PlatanistaGames",
        description: "PlatanistaGames (Daily pack)",
        amount: 5.0,
        validity_in_days: 1,
        logo: "/assets/images/Dob Services/PlatanistaGames.png",
        banner: "/assets/images/Dob Services/PlatanistaGames-banner.jpg",
    },
];
